<template>
  <!-- [DaWo] 05-11-2020 Added this badge for the navbar items. <base-item> is called by <base-group-item> and <base-subgroup-item> -->
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :dark="$vuetify.theme.dark"
    @click="$emit('base-item-click', item)"
  >
    <mex-badge v-if="item.badge" :content="item.badge.content" :value="item.badge.value" :color="item.badge.color">
      <!-- 29-10-2020 [DaWo]: If icon is provided dont generate text icon -->
      <v-list-item-icon v-if="!iconProvided" class="v-list-item__icon--text" v-text="computedText" />

      <v-list-item-icon v-else>
        <v-icon small>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content v-if="item.title || item.subtitle" class="mr-2">
        <v-list-item-title v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </mex-badge>
    <template v-else>
      <!-- 29-10-2020 [DaWo]: If icon is provided dont generate text icon -->
      <v-list-item-icon v-if="!iconProvided" class="v-list-item__icon--text" v-text="computedText" />

      <v-list-item-icon v-else>
        <v-icon small>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content v-if="item.title || item.subtitle">
        <v-list-item-title v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
import Themeable from 'vuetify/lib/mixins/themeable';

export default {
  name: 'Item',

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeColor: 'red',
    };
  },
  computed: {
    // 29-10-2020 [DaWo]: Check if icon is provided
    iconProvided() {
      return !(!this.item || !this.item.icon);
    },
    computedText() {
      if (!this.item || !this.item.title) return '';

      let text = '';

      this.item.title.split(' ').forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? '#' : undefined);
    },
  },
  methods: {
    setActiveColor() {
      this.activeColor = this.$vuetify.theme.dark ? 'white' : 'black';
    },
  },
  created() {
    this.setActiveColor();
  },
};
</script>
<style scoped>
.v-list-group__header .v-list-item--active .v-list-item__title {
  color: var(--v-activeColor);
}

.v-list .v-list-item__title {
  color: var(--v-activeColor);
}
</style>
